<template>
    <div id="addressManagement">
        <div class="navbar">
            <button @click="returnPage"><i class="iconfont icon-fanhui1"></i>地址管理</button>
        </div>

        <div class="content">
            <van-address-list
            v-model="chosenAddressId"
            :switchable="false"
            :list="list"
            default-tag-text="默认"
            @add="onAdd"
            @edit="onEdit"
            />
        </div>

        <van-popup v-model="editPopup" class="editPopup" :overlay="false">
            <div class="navbar">
                <button @click="editPopup = false"><i class="iconfont icon-fanhui1"></i>{{isAdd ? '新增地址' : '编辑地址'}}</button>
            </div>
            <div class="content">
                <van-address-edit
                :area-list="areaList"
                :show-postal="false"
                :show-delete="false"
                show-set-default
                show-search-result
                :search-result="searchResult"
                :area-columns-placeholder="['请选择', '请选择', '请选择']"
                :address-info="addressInfo"
                @save="onSave"
                @delete="onDelete"
                @change-detail="onChangeDetail"
                />
            </div>
        </van-popup>



    </div>
</template>

<script>
import {getAddress, addAddress, editAddress} from '../../api/user'
import areaList from '../../libs/city'
import lzCity from '../../libs/lzCity'
import lzCity2 from '../../libs/lzCity2'
export default {
    data () {
        return {
            chosenAddressId: '1',
            list: [],
            editPopup: false,
            areaList,
            searchResult: [],
            addData: { //新增地址入参
                region: '', 
                postCode: '',
                regionId: '',
                address: '',
                addressDetail: '',
                shipTo: '',
                phone: '',
                isDefault: '',
            },
            addressInfo: {},
            isAdd: false, //true为新增 false为编辑

        }
    },
    mounted () {
        this.getAddress();
        console.log(this.isComponent)
    },
    props: {
        isComponent: Boolean
    },
    methods: {
         onAdd() {
            console.log('新增地址');
            this.addressInfo = {};
            this.isAdd = true;
            this.editPopup = true;
        },
        onEdit(item, index) {
            console.log(item);
            this.$set(this.addressInfo, 'id', item.id);
            this.$set(this.addressInfo, 'name', item.name);
            this.$set(this.addressInfo, 'tel', item.tel);
            this.$set(this.addressInfo, 'addressDetail', item.addressDetail);
            this.$set(this.addressInfo, 'areaCode', item.postCode);
            this.$set(this.addressInfo, 'isDefault', item.isDefault);
            console.log('编辑地址');
            this.isAdd = false;
            this.editPopup = true;
        },

        async onSave(content) {
            const {isAdd} = this;
            // console.log(content);
            const {addressDetail, areaCode, city, country, county, isDefault, name, postalCode, province, tel, id} = content;
            // console.log(lzCity[`${areaCode}`]);
            // console.log(lzCity[`${lzCity2[`${city}${county}`]}`]);
            this.addData.regionId = lzCity[`${lzCity2[`${city}${county}`]}`];
            this.addData.region = `${province},${city},${county}`;
            this.addData.postCode = areaCode;
            this.addData.address = city+county;
            this.addData.addressDetail = addressDetail;
            this.addData.shipTo = name;
            this.addData.phone = tel;
            this.addData.isDefault = isDefault;
            if (isAdd) {
                let res = await addAddress(this.addData);
                console.log(res);
                const {code, data, msg} = res;
                if (code === 1) {
                    this.$toast('创建成功');
                    this.editPopup = false;
                    this.getAddress();
                }
            } else {
                this.addData.id = id;
                let res = await editAddress(this.addData);
                const {code, data, msg} = res;
                if (code == 1) {
                    this.$toast('修改成功');
                    this.editPopup = false;
                    this.getAddress();
                }
                delete this.addData.id;
            }
            
        },

        async addAddress (content) {
            
        },

        onDelete() {
            console.log('delete');
        },

        onChangeDetail(val) {
           console.log(val);
        },

        /* 获取收货地址 */
        async getAddress () {
            let res = await getAddress();
            console.log(res);
            const {code, data, msg} = res;
            if (code === 1) {
                this.list = [];
                data.map(data => {
                    var arr = {};
                    arr.id = data.id;
                    arr.name = data.shipTo;
                    arr.tel = data.phone;
                    arr.address = data.address + data.addressDetail;
                    arr.addressDetail = data.addressDetail;
                    arr.isDefault = data.isDefault;
                    arr.region = data.region;
                    arr.postCode = data.postCode;
                    this.list.push(arr);
                    
                })
            }
        },
        /* 返回上一步页面 */
        returnPage () {
            const {isComponent} = this;
            if (isComponent) {
                this.$emit('update:clearPopup', false);
            } else {
                this.$router.go(-1);
            }
        }
    }
}
</script>

<style lang="less" src="@/style/user/addressManagement.less">

</style>